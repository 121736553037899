window.jQuery = window.$ = require('jquery');
var processInclude = require('./util');
require("@babel/polyfill");

$(document).ready(function () {
    processInclude(require('./components/menu'));
    processInclude(require('./components/newsletterSignup'));
    processInclude(require('./components/consentTracking'));
    processInclude(require('./components/footer'));
    processInclude(require('./components/miniCart'));
    processInclude(require('./components/collapsibleItem'));
    processInclude(require('./components/search'));
    processInclude(require('./components/clientSideValidation'));
    processInclude(require('./components/countrySelector'));
    processInclude(require('./components/trapModalFocus'));
    require('./components/cookie').manageCookiesAlert();
    require('./components/carousel').initialize();
    
    var setdatalayer = require('./gtm/setdatalayer');
    setdatalayer.PromotionalBanners();
    setdatalayer.PromotionClickHandler();

    if ($('.lazyload').length > 0) {
        require('lazyload');
        $('.lazyload').lazyload();
    }
});

require('./thirdParty/bootstrap');
require('./components/spinner');
require('./thirdParty/slick');
require('./components/srcset-polyfill');