'use strict';

module.exports = function () {
    $("body").on("shown.bs.modal", ".modal", function (e) {

        var dialog = e.target;
        var focuspoint = $("a, button, :input, [tabindex]", dialog).get(0);
        focuspoint.focus();

        $(document).on("focusin.bft", function (e) {
            if (!($.contains(dialog, e.target))) {
                focuspoint.focus();
            }
        });
    });

    $("body").on("hide.bs.modal", ".modal", function (e) {
        $(document).off("focusin.bft");
    });
}
